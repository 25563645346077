<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <!-- {{ title }} -->
        <div class="market-main-sub-tabs w-scroll-style">
            <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :class="'market-main-sub-tab-item ' + (item.name == tabName ? 'active' : '') ">
                 <router-link :to="{name: item.name, params: { name: $route.params.name }, }">
                    <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </router-link>
            </div>
        </div>
    </div>
    <div class="col-12">
        <MarketTechnical></MarketTechnical>
    </div>
</div>
</template>



<style>
    #help-box {
        height: 100%;
    }
    @media (max-width:520px) {
        #help-box .help-navigation-bar {
            position: fixed;
        }
    }
    #help-box .help-box-icon-close {
        margin-top: 10px;
    }

    @media (min-width: 1023px) {
        .header-statement {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1023px) {
        .top-iwmf {
            display: none;
        }
    }
    @media only screen and (max-width: 760px) {
        .header-statement {
            margin-bottom:10px ;
        }
    }
    .header-statement {
        font-size: 15px;
        padding: 11px;
        font-weight: 600;
        background: #f4d88f;
        color: #482801;
        position: relative;
    }

    .header-statement:before {
        content: '';
        display: block;
        background: url("https://static.tgju.org/images/icon-background-pt1.png");
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0.12;
    }

    .header-statement-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center
    }

    .header-statement img {
        float: right;
        margin-top: 5px;
        margin-left: 10px;
    }

    .header-statement a {
        color: #111;
    }

        .header-statement a.more-button {
            background: rgba(0,0,0,.6);
            display: inline-block;
            margin-right: 5px;
            border-radius: 3px;
            color: white;
            padding: 0px 8px 1px;
        }

    .header-statement strong {
        background: rgba(100, 50, 0, 0.5);
        padding: 5px 7px 6px;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        display: inline-block;
        line-height: 1;
    }
    @media only screen and (max-width: 760px) {
        table thead th .h2 a {
            color: #fff !important;
        }
    }
    @media only screen and (max-width: 500px) {
        .header-statement img
        {
        }
        .header-statement-wrapper{
            font-size: 11px;
            align-items: center;
            white-space: nowrap;
        }
    }

    .iwmf-text,
    .header-statement-wrapper
    {
        color: #482801;
    }

    ul.header-breadcrumb-news-line {

    }

    ul.header-breadcrumb-news-line li {
        display: none;
    }

    ul.header-breadcrumb-news-line li.active {
        display: block;
    }

    .tgju-comment iframe {
        border: none;
        width: 100%;
    }

    html body.profile .profile-technical .tv-iframe-wrapper .tv-btns-wrapper {
        right: 192px;
    }
    html .tv-iframe-wrapper .tv-fullscreen {
        display: none !important;
    }

    .post-row .post-img {
        display: none;
    }
</style>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketTechnical from "@/components/Market/Profile/Technical";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Market/Profile/Header';

export default {
    name: "marketTechnical",
    components: {
        MarketTechnical,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketTechnical',
        };
    },
    mounted() {
    },
};
</script>
